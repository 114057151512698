import React from 'react';
import './style.sass';

const Header = () => (
  <div
    className="Header"
  >
    <div
      id="title"
    >
      Chat App
    </div>
  </div>
);

export default Header;
